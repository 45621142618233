















































































import { defineComponent } from '@vue/composition-api'
import router from '@/router'

export default defineComponent({
  name: 'DeskProduct',
  setup() {
    const productImg = {
      zlzy: 'https://zyb-zhike-file.cdnjtzy.com/mis/51d6f479-f99e-4617-a2e2-27bea34cd691.png',
      zhkt: 'https://zyb-zhike-file.cdnjtzy.com/mis/afa9a584-f983-46c5-975a-aa2fdb67707a.png',
      khfw: 'https://zyb-zhike-file.cdnjtzy.com/mis/7f188b3f-11fc-47b9-a6d9-e5dd7d4b59d3.jpg',
      zhty: 'https://zyb-zhike-file.cdnjtzy.com/mis/7b558866-2222-40a4-8453-cf63e7808feb.png'
    }
    const handleRedirect = (path: string) => {
      router.push({ path })
    }
    return {
      productImg,
      handleRedirect
    }
  }
})
